import React from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import { useStaticQuery, graphql } from 'gatsby'
import { Layout, SimpleHeader } from '@jelly/components'
import { Button } from '@jelly/ui'
import { pushGtmData } from '@jelly/utils'
import Resource from './_components/Resource'
import resourceData from './_components/resourceData'

const META = {
	canonical: `${process.env.GATSBY_SITE_HOST}/marketing-resources/`,
	description:
		'business.com has worked with some of the biggest names to help start and grow SMBs. Find out more about these initiatives.',
	robots: 'index, follow',
	title: 'Marketing Resources - business.com',
}

// @TODO replace with dynamic content?
const HEADER_PROPS = {
	breadcrumbs: { primary: { name: 'Marketing Resources', slug: 'marketing-resources' } },
	subTitle: 'Guide your company to successful outcomes. Get marketing strategies and tactics to implement now.',
	title: 'Full-Funnel Marketing Resources',
}

const OuterWrapper = styled.div`
	width: 100%;
`
const InnerWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 90%;

	${({ theme }) => theme.MQ.M} {
		margin: 2rem auto;
		width: 95%;
	}

	${({ theme }) => theme.MQ.XL} {
		${({ theme }) => theme.maxWidth.L};
		width: 100%;
	}
`
const Resources = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-top: 3rem;
	width: 100%;
`

function getImageByFilename(haystack, needle) {
	const entry = haystack.find((obj) => obj.node.base === needle)
	return entry ? entry.node.childImageSharp.fluid : null
}

const handleCtaClick = () => {
	pushGtmData({
		event: 'gaEvent',
		gaEventCategory: 'Mktg Resource Click',
		gaEventAction: 'CTA Button',
		gaEventLabel: 'button',
		gaEventNoninteraction: false,
	})
	window.location = 'https://www.business.com/connect/'
}

function MarketingResources() {
	const data = useStaticQuery(graphql`
		query {
			allFile(
				filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "resourcesPage" } }
			) {
				edges {
					node {
						base
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`)
	const mergedResourceData = resourceData.map((resource) => ({
		...resource,
		fluid: getImageByFilename(data.allFile.edges, resource.image),
	}))
	return (
		<OuterWrapper>
			<Layout inverseLinkColors={true} meta={META}>
				<SimpleHeader canonical={META.canonical} {...HEADER_PROPS} />
				<InnerWrapper>
					<p>
						Our variety of full-funnel marketing solutions can help your company achieve success and fill
						gaps at any stage of the buyer journey.
					</p>
					<Button onClick={handleCtaClick}>CONNECT WITH US</Button>
					<Resources>
						{mergedResourceData.map((resource, i) => (
							<Resource {...resource} key={i} />
						))}
					</Resources>
				</InnerWrapper>
			</Layout>
		</OuterWrapper>
	)
}

export default withTheme(MarketingResources)
